<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"> {{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"> {{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [
        { name: "Add or New", value: "add_or_new" },
        { name: "Amend", value: "amend" },
      ],
      selected: null,
    };
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {},
};
</script>
