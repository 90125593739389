<template>
  <vx-card title="Upload Sales Price Form">
    <vs-tabs>
      <vs-tab label="Form">
        <div class="tab-text">
          <div class="vx-row mb-2">
            <!-- <div class="vx-col w-full">
              <label class="vs-input--label">Division</label>
              <Supplier @data="setSupplier" :isDisabled="disabledDivision" />
            </div> -->
            <!-- <div class="vx-col w-full">
              <label class="vs-input--label">Select Action</label>
              <SelectAction @data="setAction" />
            </div> -->
            <div class="vx-col w-full">
              <label class="vs-input--label">Upload Template</label>
              <br />
              <div class="flex gap-2">
                <vs-input
                  type="file"
                  @change="onUploadTemplate"
                  v-model="partial.upload_template"
                />
                <vs-button
                  color="primary"
                  size="small"
                  type="filled"
                  @click="clickDownload"
                >
                  Download template
                </vs-button>
                <!-- <vs-button color="danger" @click="resetFilterDivision"
                  >Reset</vs-button
                > -->
              </div>
              <div v-show="priceID != null" class="my-4">
                <vs-button
                  color="success"
                  type="filled"
                  class="small"
                  target
                  :href="{ url: form.file_price_item_uploaded }"
                  >Download File</vs-button
                >
              </div>
            </div>
          </div>
          <div class="vx-row my-6">
            <div class="vx-col flex gap-3">
              <vs-button color="danger" type="filled" @click="clickBack">
                Cancel
              </vs-button>
              <vs-button color="primary" type="filled" @click="clickSave">
                Save
              </vs-button>
            </div>
          </div>
        </div>
      </vs-tab>
      <vs-tab label="Progress">
        <div class="tab-text">
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <table-import ref="tableImport" :filter="filterTableImport" />
            </div>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import Supplier from "@/components/master/Supplier.vue";
import SelectAction from "./_components/select-action.vue";
import TableImport from "@/components/import/Table.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const PricingManagementV3Repository = RepositoryFactory.get(
  "pricingManagementV3"
);

export default {
  components: {
    Supplier,
    SelectAction,
    TableImport,
  },
  data() {
    return {
      filterTableImport: {
        type: "price-management-v3",
        source: "OMS",
      },
      code: "",
      created_by: "",
      form: {
        supplier_id: 0,
        file_price_item: "",
        action: "add_or_new",
      },
      partial: {
        upload_template: null,
      },
      submitForm: null,
      disabledDivision: false,
      priceID: null,
    };
  },
  methods: {
    async validate() {
      let message = "";
      if (this.form.file_price_item == null) {
        message = "File Price Item is required";
      }

      return message;
    },
    async clickSave() {
      const message = await this.validate();
      if (message != "") {
        this.$vs.notify({
          title: "Error",
          text: message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      this.$vs.loading();
      try {
        const formData = new FormData();
        formData.append("supplier_id", this.form.supplier_id);
        formData.append("action", this.form.action);
        formData.append("file_price_item", this.form.file_price_item);

        const resp = await PricingManagementV3Repository.createSkuUploadForm(
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (resp.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: resp.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check-circle",
          });

          this.$router.push({
            name: "pricing-management-v3",
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        console.log(error);
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
      this.$vs.loading.close();
    },
    async clickBack() {
      this.$router.go(-1);
    },
    async setAction(action) {
      this.form.action = action.value;
    },
    async setSupplier(supplier) {
      this.form.supplier_id = supplier.ID;
    },
    async onUploadTemplate(event) {
      this.form.file_price_item = event.target.files[0];
      // disabled division
      this.disabledDivision = true;
    },
    async resetFilterDivision() {
      this.form.supplier_id = 0;
      this.partial.upload_template = null;
      this.form.file_price_item = null;
      this.disabledDivision = false;
    },
    async clickDownload() {
      // if (this.form.supplier_id == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Supplier is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }
      this.$vs.loading();
      try {
        const supplier_id = this.form.supplier_id;
        let params = {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
          params: {
            supplier_id: supplier_id,
          },
        };

        const response =
          await PricingManagementV3Repository.downloadExcelSkuUploadForm(
            params
          );
        var fileURL = window.URL.createObjectURL(new Blob([response]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let fileTitle = `TEMPLATE-SKU-Upload-Form`;

        fileLink.setAttribute("download", fileTitle + ".xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        console.log(error);
        this.$vs.notify({
          title: "Error",
          text: "Error while downloading template",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
      this.$vs.loading.close();
    },
  },
};
</script>
```
